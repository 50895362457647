@use "./../variables.scss";

.page#who-are-we {
  margin-top: -80px;
  padding-bottom: 50px;
  // background-color: var(--main-color);
  background-image: url('../images/who-are-we-background.svg');
  background-size: cover;
  background-repeat: repeat-x;
  display: flex;
  flex-direction: column;
  // padding-top: calc(var(--logo-width) * 2);
  padding-top: 130px;
  @media only screen and (max-width: 700px) {
    padding-top: 80px;
  }

  > .title {
    align-self: center;
    max-width: 1140px;
    margin-bottom: 50px;
    font-family: "BP_Font_Test";
    text-transform: uppercase;
    font-size: 40px;
  }
  
  .description {
    text-align: justify;
    text-align-last: center;
    align-self: center;
    max-width: 1140px;
    margin-bottom: 50px;
    padding: 0 60px;
    font-size: 20px;
    @media only screen and (max-width: 850px) {
      margin-bottom: 5px;
      font-size: 16px
    }
    @media only screen and (max-width: 400px) {
      font-size: 14px;
    }
  }

  .profiles {
    display: flex;
    align-self: center;
    width: 100%;
    max-width: 1300px;
    @media only screen and (max-width: 850px) {
      flex-direction: column;
    }

    .profile {
      height: 350px;
      margin: 15px;
      display: flex;
      flex-direction: column;
      flex: 1;

      @media only screen and (max-width: 600px) {
        margin: 0px 50px;
      }

      .picture {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        align-self: center;
        width: 250px;
        height: 250px;
        margin-bottom: 10px;
        position: relative;

        .linkedIn {
          width: 30px;
          height: 30px;
          background-image: url('../images/buttons/linkedin.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          right: 15px;
          bottom: 15px;
          cursor: pointer;
        }

      }

      .title {
        width: 300px;
        align-self: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        flex: 1;
      }

      @media only screen and (max-width: 1000px) {
        .picture {
          width: 200px;
          height: 200px;
        }
        .title {
          background-position: top;
          width: 250px;
        }
      }

      @media only screen and (max-width: 850px) {
        flex-direction: row;
        .picture {
          width: 150px;
          height: 150px;
          flex: 1;
          background-position: 80%;
        }
        .title {
          background-position: center;
          height: 128px;
          flex:2;
          margin: 10px 50px;
        }
      }

      @media only screen and (max-width: 400px) {
        flex-direction: row;
        .picture {
          width: 50px;
          height: 50px;
        }
        .title {
          height: 50px;
        }
      }
      &#kg {
        .picture {
          background-image: url('../images/who-are-we/kyle-gaynier-profile.png');
        }
        .title {
          background-image: url('../images/who-are-we/kyle-gaynier_head-writer.svg');
        }
      }

      &#ch {
        .picture {
          background-image: url('../images/who-are-we/carmen-helle-profile.png');
        }
        .title {
          background-image: url('../images/who-are-we/carmen-helle_production-manager.svg');
        }
      }
      &#bp {
        .picture {
          background-image: url('../images/who-are-we/benjamin-paulus-profile.png');
        }
        .title {
          background-image: url('../images/who-are-we/benjamin-paulus_art-director.svg');
        }
      }

    }
  }
  
}
