@use "./variables.scss";
@use "./pages/projects.scss";
@use "./pages/who-are-we.scss";
@use "./pages/contact.scss";
@use "./popup.scss";

@font-face {
  font-family: "BP_Font_Test";
  src: url("../fonts/bp.woff2") format("woff2")
}

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100vw;
}

main {
  color: white;
  font-family: 'Nunito', sans-serif;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  width: 100vw;
}

.page {
  // background-color: var(--main-color);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--nav-bar-height);
  position: relative;
  scroll-snap-align: center;
}

.navigation {
  font-family: "BP_Font_Test";
  align-items: center;
  background-color: var(--main-color);
  box-sizing: border-box;
  display: flex;
  height: var(--nav-bar-height);
  left: 0;
  padding: 0 40px;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 5;
  @media only screen and (max-width: 700px) {
    overflow: hidden;
    flex-direction: column;
    .nav-button {
      margin: 10px;
    }
  }

  &.mobile {

    &.expanded {
      height: 450px;

      .nav-close-mobile {
        display: block;
      }

      .nav-open-mobile {
        display: none;
      }

      &.sub-menu-visible {
        height: 800px;
        .sub-menu-projects {
          top: 120px;
        }
        .nav-stories {
          margin-bottom: 290px;
        }
      }
    }

    .nav-open-mobile {
      display: block;
      position:absolute;
      top: 2px;
      width: 40px;
      height: 40px;
      background-image: url("../images/buttons/menu-open.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }

    .nav-close-mobile {
      display: none;
      position:absolute;
      top: 2px;
      width: 40px;
      height: 40px;
      background-image: url("../images/buttons/menu-close.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
  }

  .seperator {
    font-size: 25px;
    padding: 0 10px;
    background-color: var(--main-color);
    height: var(--nav-bar-height);
    z-index: 2;
    align-items: center;
    display: flex;
    @media only screen and (max-width: 700px) {
      display: none;
    }
  }
  
  &.mobile {

    .nav-stories {
      margin-top: 60px;
    }

    .sub-menu-projects {
      text-align: center;
    }
  }

  .nav-button {
    align-items: center;
    // background-color: var(--main-color);
    cursor: pointer;
    display: flex;
    height: var(--nav-bar-height);
    justify-content: center;
    // width: 200px;
    padding: 0 5px;
    font-size: 25px;
    z-index: 2;

    &.coming-soon {
      color: var(--main-color-disabled);
      cursor:default;
      &:hover {
        color: var(--main-color-disabled);
      }
    }

    &:hover {
      color: var(--main-color-darker);
    }

    &.active {
      color: #212121;
      &:hover {
        color: var(--main-color-darkest);
      }
    }
  }

  .sub-menu-projects {
    z-index: 4;
    display: none;
    &.visible {
      display: block;
    }

    position: absolute;
    top: var(--nav-bar-height);
    left: var(--nav-button-padding);
    background-color: var(--main-color);
    border-radius: 0 0 10px 10px;
    padding: 15px;
    z-index: 1;

    .sub-nav-button {
      line-height: 2;
      cursor: pointer;
      // pointer-events: none;
      &:hover {
        color: var(--main-color-darker);
      }
    }
  }
}

.logo {
  height: var(--logo-width);
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  top: calc(var(--nav-bar-height) - 1px);
  width: var(--logo-width);
  z-index: 2;
  background-image: url("../images/paganini-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}
