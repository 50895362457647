:root {
  --main-color: #ec1653;
  --main-color-darker: #b40939;
  --main-color-darkest: #750323;
  --main-color-disabled: #cb1b4e;
  --popup-background-color: #00000ac9;
  --logo-width: 100px;
  --nav-bar-height: 100px;
  --nav-button-height: 40px;
}

@media only screen and (max-width: 700px) {
  :root {
    --logo-width: 80px;
    --nav-bar-height: 50px;
  }
}

.page#stories {
  background-color: #000;
  overflow: hidden;
}

.page#stories .next-btn, .page#stories .prev-btn {
  width: 50px;
  height: 50px;
  z-index: 3;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -25px;
  position: absolute;
  top: 50%;
}

.page#stories .prev-btn {
  background-image: url("left.bb16733c.svg");
  left: 20px;
}

.page#stories .next-btn {
  background-image: url("right.02fec264.svg");
  right: 20px;
}

.page#stories .progress-bullets {
  z-index: 1;
  width: 100%;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 100px;
}

.page#stories .progress-bullets .bullet {
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 2.5px;
}

.page#stories .progress-bullets .bullet:nth-child(1) {
  background-image: url("1.0e3f6814.svg");
}

.page#stories .progress-bullets .bullet:nth-child(1).active {
  background-image: url("1-active.55bd1a1e.svg");
}

.page#stories .progress-bullets .bullet:nth-child(2) {
  background-image: url("2.0e3f6814.svg");
}

.page#stories .progress-bullets .bullet:nth-child(2).active {
  background-image: url("2-active.55bd1a1e.svg");
}

.page#stories .progress-bullets .bullet:nth-child(3) {
  background-image: url("3.ff6672e4.svg");
}

.page#stories .progress-bullets .bullet:nth-child(3).active {
  background-image: url("3-active.3f3fe3e4.svg");
}

.page#stories .progress-bullets .bullet:nth-child(4) {
  background-image: url("4.e5a30cf7.svg");
}

.page#stories .progress-bullets .bullet:nth-child(4).active {
  background-image: url("4-active.97f08e76.svg");
}

.page#stories .progress-bullets .bullet:nth-child(5) {
  background-image: url("5.9b3ad094.svg");
}

.page#stories .progress-bullets .bullet:nth-child(5).active {
  background-image: url("5-active.ebff4efd.svg");
}

.page#stories .progress-bullets .bullet:nth-child(6) {
  background-image: url("6.ff6672e4.svg");
}

.page#stories .progress-bullets .bullet:nth-child(6).active {
  background-image: url("6-active.3f3fe3e4.svg");
}

.page#stories .project {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  transform: translate(100vw);
}

.page#stories .project .title {
  width: 40%;
  height: 40%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.page#stories .project .background {
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.page#stories .project .background img {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 700px) {
  .page#stories .project.rabarber .title {
    margin-top: 0;
  }
}

.page#stories .more {
  width: 140px;
  height: 80px;
  z-index: 4;
  cursor: pointer;
  background-image: url("more.6ca4017f.svg");
  background-position: center;
  background-repeat: no-repeat;
  align-self: center;
  position: absolute;
  bottom: 20%;
}

.page#stories[label="gods-untold"] .more {
  width: 200px;
  height: 100px;
  background-image: url("kickstarter-logo-green.2a783068.png");
  background-size: contain;
}

.page#who-are-we {
  background-image: url("who-are-we-background.55d706ae.svg");
  background-repeat: repeat-x;
  background-size: cover;
  flex-direction: column;
  margin-top: -80px;
  padding-top: 130px;
  padding-bottom: 50px;
  display: flex;
}

@media only screen and (max-width: 700px) {
  .page#who-are-we {
    padding-top: 80px;
  }
}

.page#who-are-we > .title {
  max-width: 1140px;
  text-transform: uppercase;
  align-self: center;
  margin-bottom: 50px;
  font-family: BP_Font_Test;
  font-size: 40px;
}

.page#who-are-we .description {
  text-align: justify;
  text-align-last: center;
  max-width: 1140px;
  align-self: center;
  margin-bottom: 50px;
  padding: 0 60px;
  font-size: 20px;
}

@media only screen and (max-width: 850px) {
  .page#who-are-we .description {
    margin-bottom: 5px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .page#who-are-we .description {
    font-size: 14px;
  }
}

.page#who-are-we .profiles {
  width: 100%;
  max-width: 1300px;
  align-self: center;
  display: flex;
}

@media only screen and (max-width: 850px) {
  .page#who-are-we .profiles {
    flex-direction: column;
  }
}

.page#who-are-we .profiles .profile {
  height: 350px;
  flex-direction: column;
  flex: 1;
  margin: 15px;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .page#who-are-we .profiles .profile {
    margin: 0 50px;
  }
}

.page#who-are-we .profiles .profile .picture {
  width: 250px;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  align-self: center;
  margin-bottom: 10px;
  position: relative;
}

.page#who-are-we .profiles .profile .picture .linkedIn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url("linkedin.4a41cd37.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.page#who-are-we .profiles .profile .title {
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex: 1;
  align-self: center;
}

@media only screen and (max-width: 1000px) {
  .page#who-are-we .profiles .profile .picture {
    width: 200px;
    height: 200px;
  }

  .page#who-are-we .profiles .profile .title {
    width: 250px;
    background-position: top;
  }
}

@media only screen and (max-width: 850px) {
  .page#who-are-we .profiles .profile {
    flex-direction: row;
  }

  .page#who-are-we .profiles .profile .picture {
    width: 150px;
    height: 150px;
    background-position: 80%;
    flex: 1;
  }

  .page#who-are-we .profiles .profile .title {
    height: 128px;
    background-position: center;
    flex: 2;
    margin: 10px 50px;
  }
}

@media only screen and (max-width: 400px) {
  .page#who-are-we .profiles .profile {
    flex-direction: row;
  }

  .page#who-are-we .profiles .profile .picture {
    width: 50px;
    height: 50px;
  }

  .page#who-are-we .profiles .profile .title {
    height: 50px;
  }
}

.page#who-are-we .profiles .profile#kg .picture {
  background-image: url("kyle-gaynier-profile.327239da.png");
}

.page#who-are-we .profiles .profile#kg .title {
  background-image: url("kyle-gaynier_head-writer.200a4a38.svg");
}

.page#who-are-we .profiles .profile#ch .picture {
  background-image: url("carmen-helle-profile.30800cbf.png");
}

.page#who-are-we .profiles .profile#ch .title {
  background-image: url("carmen-helle_production-manager.d9c94e49.svg");
}

.page#who-are-we .profiles .profile#bp .picture {
  background-image: url("benjamin-paulus-profile.3bea9a78.png");
}

.page#who-are-we .profiles .profile#bp .title {
  background-image: url("benjamin-paulus_art-director.7e617ad4.svg");
}

.page#contact {
  min-height: 60vh;
  background-color: var(--main-color-darker);
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  display: flex;
}

.page#contact .title {
  flex: 1;
  margin-bottom: 20px;
  font-family: BP_Font_Test;
  font-size: 30px;
}

.page#contact .socials {
  width: 40%;
  max-width: 500px;
  flex: 3;
  justify-content: center;
  align-items: center;
  display: flex;
}

.page#contact .socials .social-btn {
  flex: 1;
  place-content: center;
  display: flex;
}

.page#contact .socials .social-btn-image {
  width: 50px;
  height: 50px;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.page#contact .socials .social-btn-image.instagram {
  background-image: url("social-instagram.ecde2a55.svg");
}

.page#contact .socials .social-btn-image.email {
  background-image: url("social-email.7489ab07.svg");
}

.page#contact .socials .social-btn-image.linkedin {
  background-image: url("social-linkedin.4871165e.svg");
}

.page#contact .address {
  text-transform: uppercase;
  flex: 1;
}

.page#contact .logos {
  flex: 3;
}

.page#contact .logos .bad-book {
  width: 180px;
  height: 100px;
  background-image: url("bad-book.5ae119c2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.page#contact .fine-print {
  text-align: center;
  flex: 1;
  font-size: small;
}

.popup {
  background-color: var(--popup-background-color);
  height: 100vh;
  width: 100vw;
  z-index: 10;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.popup .pop-up-content {
  height: 80vh;
  max-height: 400px;
  max-width: 600px;
  width: 80vw;
}

.popup .pop-up-content .pitch-viewer {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.popup .pop-up-content .close {
  width: 50px;
  height: 50px;
  z-index: 3;
  cursor: pointer;
  background-image: url("dialog-close.e329d845.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 5%;
  right: 5%;
}

.popup .pop-up-content .project-popup {
  width: 100vw;
  height: 100vh;
}

.popup .pop-up-content .project-popup.inactive {
  display: none;
}

.popup .pop-up-content .project-popup .project {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  transform: translate(100vw);
}

.popup .pop-up-content .project-popup .project .background {
  width: 1280px;
  height: 920px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.popup .pop-up-content .project-popup .project .background img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.popup .pop-up-content .project-popup .next-btn, .popup .pop-up-content .project-popup .prev-btn {
  width: 50px;
  height: 50px;
  z-index: 3;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -25px;
  position: absolute;
  top: 50%;
}

.popup .pop-up-content .project-popup .next-btn.hidden, .popup .pop-up-content .project-popup .prev-btn.hidden {
  display: none;
}

.popup .pop-up-content .project-popup .prev-btn {
  background-image: url("left-red.1b1b70bb.svg");
  margin-left: -560px;
  left: 50%;
}

.popup .pop-up-content .project-popup .next-btn {
  background-image: url("right-red.0d77b2d6.svg");
  margin-right: -560px;
  right: 50%;
}

.popup .pop-up-content .project-popup .progress-bullets {
  z-index: 1;
  width: 100%;
  justify-content: center;
  margin-top: 340px;
  display: flex;
  position: absolute;
  top: 50%;
}

.popup .pop-up-content .project-popup .progress-bullets .bullet {
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 2.5px;
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(1) {
  background-image: url("1.0e3f6814.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(1).active {
  background-image: url("1-active.55bd1a1e.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(2) {
  background-image: url("2.0e3f6814.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(2).active {
  background-image: url("2-active.55bd1a1e.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(3) {
  background-image: url("3.ff6672e4.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(3).active {
  background-image: url("3-active.3f3fe3e4.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(4) {
  background-image: url("4.e5a30cf7.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(4).active {
  background-image: url("4-active.97f08e76.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(5) {
  background-image: url("5.9b3ad094.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(5).active {
  background-image: url("5-active.ebff4efd.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(6) {
  background-image: url("6.ff6672e4.svg");
}

.popup .pop-up-content .project-popup .progress-bullets .bullet:nth-child(6).active {
  background-image: url("6-active.3f3fe3e4.svg");
}

.popup .pop-up-content .project-popup .progress-bullets.hidden {
  display: none;
}

.popup.active {
  display: flex;
}

@media only screen and (max-width: 1300px) {
  .popup .pop-up-content .project-popup .project .background {
    width: 1080px;
    height: 720px;
  }

  .popup .pop-up-content .project-popup .prev-btn {
    margin-left: -480px;
  }

  .popup .pop-up-content .project-popup .next-btn {
    margin-right: -480px;
  }

  .popup .pop-up-content .project-popup .progress-bullets {
    margin-top: 300px;
  }
}

@media (orientation: portrait) {
  .popup .pop-up-content .close {
    width: 40px;
  }

  .popup .pop-up-content .project-popup .project .background {
    width: 800px;
    height: 640px;
  }

  .popup .pop-up-content .project-popup .prev-btn {
    width: 40px;
    margin-left: -250px;
  }

  .popup .pop-up-content .project-popup .next-btn {
    width: 40px;
    margin-right: -250px;
  }

  .popup .pop-up-content .project-popup .progress-bullets {
    margin-top: 320px;
  }
}

@media (orientation: portrait) and (max-width: 450px) {
  .popup .pop-up-content .project-popup .prev-btn {
    margin-left: -160px;
  }

  .popup .pop-up-content .project-popup .next-btn {
    margin-right: -160px;
  }

  .popup .pop-up-content .project-popup .progress-bullets {
    margin-top: 275px;
  }
}

@media (orientation: portrait) and (max-width: 300px) {
  .popup .pop-up-content .project-popup .prev-btn {
    margin-left: -120px;
  }

  .popup .pop-up-content .project-popup .next-btn {
    margin-right: -120px;
  }

  .popup .pop-up-content .project-popup .progress-bullets {
    margin-top: 205px;
  }
}

@font-face {
  font-family: BP_Font_Test;
  src: url("bp.0f98ff50.woff2") format("woff2");
}

html, body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

main {
  color: #fff;
  height: 100vh;
  scroll-snap-type: y mandatory;
  width: 100vw;
  font-family: Nunito, sans-serif;
  overflow-y: scroll;
}

.page {
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: var(--nav-bar-height);
  scroll-snap-align: center;
  flex-direction: column;
  display: flex;
  position: relative;
}

.navigation {
  background-color: var(--main-color);
  box-sizing: border-box;
  height: var(--nav-bar-height);
  width: 100vw;
  z-index: 5;
  align-items: center;
  padding: 0 40px;
  font-family: BP_Font_Test;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 700px) {
  .navigation {
    flex-direction: column;
    overflow: hidden;
  }

  .navigation .nav-button {
    margin: 10px;
  }
}

.navigation.mobile.expanded {
  height: 450px;
}

.navigation.mobile.expanded .nav-close-mobile {
  display: block;
}

.navigation.mobile.expanded .nav-open-mobile {
  display: none;
}

.navigation.mobile.expanded.sub-menu-visible {
  height: 800px;
}

.navigation.mobile.expanded.sub-menu-visible .sub-menu-projects {
  top: 120px;
}

.navigation.mobile.expanded.sub-menu-visible .nav-stories {
  margin-bottom: 290px;
}

.navigation.mobile .nav-open-mobile {
  width: 40px;
  height: 40px;
  background-image: url("menu-open.8682654b.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  top: 2px;
}

.navigation.mobile .nav-close-mobile {
  width: 40px;
  height: 40px;
  background-image: url("menu-close.948e978b.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
  position: absolute;
  top: 2px;
}

.navigation .seperator {
  background-color: var(--main-color);
  height: var(--nav-bar-height);
  z-index: 2;
  align-items: center;
  padding: 0 10px;
  font-size: 25px;
  display: flex;
}

@media only screen and (max-width: 700px) {
  .navigation .seperator {
    display: none;
  }
}

.navigation.mobile .nav-stories {
  margin-top: 60px;
}

.navigation.mobile .sub-menu-projects {
  text-align: center;
}

.navigation .nav-button {
  cursor: pointer;
  height: var(--nav-bar-height);
  z-index: 2;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  font-size: 25px;
  display: flex;
}

.navigation .nav-button.coming-soon {
  color: var(--main-color-disabled);
  cursor: default;
}

.navigation .nav-button.coming-soon:hover {
  color: var(--main-color-disabled);
}

.navigation .nav-button:hover {
  color: var(--main-color-darker);
}

.navigation .nav-button.active {
  color: #212121;
}

.navigation .nav-button.active:hover {
  color: var(--main-color-darkest);
}

.navigation .sub-menu-projects {
  z-index: 4;
  top: var(--nav-bar-height);
  left: var(--nav-button-padding);
  background-color: var(--main-color);
  z-index: 1;
  border-radius: 0 0 10px 10px;
  padding: 15px;
  display: none;
  position: absolute;
}

.navigation .sub-menu-projects.visible {
  display: block;
}

.navigation .sub-menu-projects .sub-nav-button {
  cursor: pointer;
  line-height: 2;
}

.navigation .sub-menu-projects .sub-nav-button:hover {
  color: var(--main-color-darker);
}

.logo {
  height: var(--logo-width);
  left: 50%;
  top: calc(var(--nav-bar-height)  - 1px);
  width: var(--logo-width);
  z-index: 2;
  background-image: url("paganini-logo.d3754b9a.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  transform: translate(-50%);
}

/*# sourceMappingURL=index.8149e32c.css.map */
