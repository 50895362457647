@use "./../variables.scss";

.page#contact {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  background-color: var(--main-color-darker);
  align-items: center;

  .title {
    font-family: "BP_Font_Test";
    font-size: 30px;
    margin-bottom: 20px;
    flex: 1;
  }

  .socials {
    width: 40%;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;

    .social-btn {
      flex: 1;
      align-content: center;
      display: flex;
      justify-content: center;
    }
    .social-btn-image {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      color: white;

      &.instagram {
        background-image: url('../images/buttons/social-instagram.svg');
      }

      &.email {
        background-image: url('../images/buttons/social-email.svg');
      }

      &.linkedin {
        background-image: url('../images/buttons/social-linkedin.svg');
      }
    }
  }

  .address {
    flex: 1;
    text-transform: uppercase;
  }

  .logos {
    flex: 3;

    .bad-book {
      width: 180px;
      height: 100px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('../images/bad-book.svg');
    }
  }

  .fine-print {
    flex: 1;
    font-size: small;
    text-align: center;
  }
}