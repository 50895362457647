:root {
  // --main-color: #cd2027;
  --main-color: #EC1653;
  --main-color-darker: #b40939;
  --main-color-darkest: #750323;
  --main-color-disabled: #cb1b4e;

  --popup-background-color: #00000ac9;

  --logo-width: 100px;

  --nav-bar-height: 100px;
  --nav-button-height: 40px;
}

// @media only screen and (max-width: 900px) {
//   .page#who-are-we {
//     .profiles {
//       .profile {
//         .picture {
//           width: 200px;
//           height: 200px;
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 700px) {
  :root {
    --logo-width: 80px;
    --nav-bar-height: 50px;
  }
}