.page#stories {
  background-color: black;
  overflow: hidden;

  .next-btn,
  .prev-btn {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 3;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    cursor: pointer;
  }

  .prev-btn {
    background-image: url(../images/buttons/left.svg);
    left: 20px;
  }

  .next-btn {
    background-image: url(../images/buttons/right.svg);
    right: 20px;
  }

  .progress-bullets {
    position: absolute;
    bottom: 100px;
    display: flex;
    z-index: 1;
    width: 100%;
    justify-content: center;

    .bullet {
      width: 15px;
      height: 15px;
      margin: 2.5px;
      background-repeat: no-repeat;
      background-size: contain;
      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          background-image: url("../images/progress/#{$i}.svg");
          &.active {
            background-image: url("../images/progress/#{$i}-active.svg");
          }
        }
      }
    }
  }

  .project {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    transform: translate(100vw, 0px);
    background-position: center center;
    background-size: cover;

    .title {
      width: 40%;
      height: 40%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      display: flex;
      img {
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .project {
      &.rabarber {
        .title {
          margin-top: 0;
        }
      }
    }
  }

  .more {
    width: 140px;
    height: 80px;
    background-image: url('../images/buttons/more.svg');
    background-position: center;
    background-repeat: no-repeat;
    z-index: 4;
    align-self: center;
    bottom: 20%;
    position: absolute;
    cursor: pointer;
  }

  &[label="gods-untold"] {
    .more {
      background-image: url('../images/buttons/kickstarter-logo-green.png');
      background-size: contain;
      width: 200;
      height: 100;
    }
  }

  .donate {

  }
}