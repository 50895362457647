@use "./variables.scss";

.popup {
  background-color: var(--popup-background-color);
  display: none;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10;
  overflow: hidden;

  .pop-up-content {
    height: 80vh;
    max-height: 400px;
    max-width: 600px;
    width: 80vw;

    .pitch-viewer {
      width:100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .close {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 3;
      position: absolute;
      top: 5%;
      cursor: pointer;
      background-image: url(../images/buttons/dialog-close.svg);
      right: 5%;
    }

    .project-popup {
      width: 100vw;
      height: 100vh;

      &.inactive {
        display: none;
      }

      .project {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        transform: translate(100vw, 0px);
        background-position: center center;
        background-size: cover;

        .background {
          width: 1280px;
          height: 920px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }

      .next-btn,
      .prev-btn {
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 3;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        cursor: pointer;
        &.hidden {
          display: none;
        }
      }

      .prev-btn {
        background-image: url(../images/buttons/left-red.svg);
        left: 50%;
        margin-left: -560px;
      }

      .next-btn {
        background-image: url(../images/buttons/right-red.svg);
        right: 50%;
        margin-right: -560px;
      }

      .progress-bullets {
        position: absolute;
        top: 50%;
        margin-top: 340px;
        display: flex;
        z-index: 1;
        width: 100%;
        justify-content: center;

        .bullet {
          width: 15px;
          height: 15px;
          margin: 2.5px;
          background-repeat: no-repeat;
          background-size: contain;
          @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
              background-image: url("../images/progress/#{$i}.svg");
              &.active {
                background-image: url("../images/progress/#{$i}-active.svg");
              }
            }
          }
        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  &.active {
    display: flex;
  }
}


// media
@media only screen and (max-width: 1300px) {
  .popup {
    .pop-up-content {

      .project-popup {
        .project {
          .background {
            width: 1080px;
            height: 720px;
          }
        }
        .prev-btn {
          margin-left: -480px;
        }

        .next-btn {
          margin-right: -480px;
        }

        .progress-bullets {
          margin-top: 300px;
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .popup {
    .pop-up-content {

      .close {
        width: 40px;
      }

      .project-popup {
        .project {
          .background {
            width: 800px;
            height: 640px;
          }
        }
        .prev-btn {
          margin-left: -250px;
          width: 40px;
        }

        .next-btn {
          margin-right: -250px;
          width: 40px;
        }

        .progress-bullets {
          margin-top: 320px;
        }
      }
    }
  }
}

@media (orientation: portrait) and (max-width: 450px) {
  .popup {
    .pop-up-content {

      .project-popup {
        .prev-btn {
          margin-left: -160px;
        }

        .next-btn {
          margin-right: -160px;
        }

        .progress-bullets {
          margin-top: 275px;
        }
      }
    }
  }
}

@media (orientation: portrait) and (max-width: 300px) {
  .popup {
    .pop-up-content {

      .project-popup {
        .prev-btn {
          margin-left: -120px;
        }

        .next-btn {
          margin-right: -120px;
        }

        .progress-bullets {
          margin-top: 205px;
        }
      }
    }
  }
}
